import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { CiLocationOn, CiMobile1 } from "react-icons/ci";
import {AiOutlineMail} from "react-icons/ai";
import {FaFacebook, FaInstagram, FaTwitterSquare, FaLinkedin, FaYoutubeSquare, FaGooglePay, FaAppStoreIos} from "react-icons/fa";


const FooterContainer = styled.div`
    ${tw`
        flex
        bg-blue-800
        items-center
        justify-center
        text-center
        text-gray-200
        text-xs
    `}
`
const FooterGrid = styled.div`
    ${tw`
        grid
        lg:grid-cols-4
        grid-cols-1
        md:grid-cols-2
        grid-cols-1
        gap-2
        lg:gap-4
        p-2
        w-full
        lg:w-[70%]
        mx-auto
    `}
`

const AboutContainer = styled.div`
    ${tw`
        lg:col-span-1
        text-xs
        flex-col
        items-start
        justify-start
        text-justify
    `}
`

const Header = styled.h5`
    ${tw`
        font-bold
        text-white
        text-xs
        mb-3
    `}
`

const ContactContainer = styled.div`
    ${tw`
        lg:col-span-1
        flex-col
        text-justify
    `}
`

const ContactList = styled.ul`
    ${tw`
        list-none
    `}
`

const ContactListItem= styled.li`
    ${tw`
        flex
        m-1
    `}
`



const FollowUsContainer = styled.div`
    ${tw`
        lg:col-span-1
        flex-col
        text-left
        gap-3
    `}
`

const SocialLinks = styled.div`
    ${tw`
        flex
        gap-3
    `}
`

const DownloadContainer = styled.div`
    ${tw`
        lg:col-span-1
        flex-col
        text-left
        gap-2
    `}
`

const Plattforms = styled.div`
    ${tw`
        flex
        gap-3
    `}
`


export function Footer(){
    return(
        <FooterContainer>
            <FooterGrid>
                <AboutContainer>
                    <Header>
                        <span>About Us</span>
                    </Header>
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                    </p>    
                </AboutContainer>

                <ContactContainer>
                    <Header>Contact</Header>

                    <ContactList>
                        <ContactListItem>
                            <CiLocationOn size={15} />
                            <p>XYZ Street , Charlesville, Ohio, United States - 43032</p>
                        </ContactListItem>

                        <ContactListItem>
                            <CiMobile1 size={15} />
                            <p>Phone: 1234567886</p>
                        </ContactListItem>
                        
                        <ContactListItem>
                            <AiOutlineMail size={15} />
                            <p>info@swimail.com</p>
                        </ContactListItem>
                    </ContactList>
                </ContactContainer>

                <FollowUsContainer>
                    <Header>Follow Us</Header>
                    <SocialLinks>
                        <FaFacebook size={20} />
                        <FaInstagram size={20} />
                        <FaTwitterSquare size={20} />
                        <FaYoutubeSquare size={20} />
                        <FaLinkedin size={20} />
                    </SocialLinks>
                </FollowUsContainer>

                <DownloadContainer>
                    <Header>Download</Header>
                    <Plattforms>
                        <FaGooglePay size={40} />
                        <FaAppStoreIos size={30} />
                    </Plattforms>
                </DownloadContainer>
            </FooterGrid>
        </FooterContainer>
    )
}
