import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const PersonalDetailContainer = styled.div`
    ${tw`
        w-full
        /*bg-yellow-400*/
        p-2
        lg:px-14
        py-5
    `}
`

const PersonalHeaderContainer = styled.div`
    ${tw`
        /*bg-pink-300*/
        text-blue-800
        text-lg
        p-2
        border-b
        border-dotted
        border-blue-800
    `}
`

const PersonalDetailsContainer = styled.div`
    ${tw`
        flex
        flex-col
        /*bg-blue-100*/
        lg:w-[500px]
        p-2
    `}
`

const FormField = styled.div`
    ${tw`
        flex
        flex-col
        items-start
        justify-start
        text-left
    `}
    label{
        ${tw`
            w-full
            text-blue-800
        `}
    }
    input, select{
        ${tw`
            w-full
            p-1
            border
            border-blue-800
            rounded-sm
            outline-none
            focus:border-2
            focus:border-blue-800

        `}
    }
`

const HostDetailsContainer = styled.div`
    ${tw`
        flex
        flex-col
        /*bg-blue-100*/
        lg:w-[500px]
        p-2
    `}
`

const ButtonContainer = styled.div`
    ${tw`
        flex
        w-full
        text-center
        items-center
        justify-center
        mt-3
        lg:w-[500px]
        
    `}
    button{
        ${tw`
            p-2
            border-2
            border-blue-800
            w-full
            bg-blue-800
            text-white
            uppercase
            hover:border-2
            hover:border-blue-800
            hover:bg-white
            hover:text-blue-800
            transition-all
            duration-200
            ease-in-out
        `}
    }
`


export function Settings(){
    return(
        <>
            <PersonalDetailContainer>
                <form>
                    
                    <PersonalHeaderContainer>
                        <h4>Personal Details</h4>
                    </PersonalHeaderContainer>

                    <PersonalDetailsContainer>
                        <FormField>
                            <label>Email</label>
                            <input type="email" name="email" disabled value="danismmtz@gmail.com" />
                        </FormField>

                        <FormField>
                            <label>Phone</label>
                            <input type="phone" name="phone" disabled value="9889532690" />
                        </FormField>

                        <FormField>
                            <label>WhatsApp</label>
                            <input type="phone" name="phone" disabled value="9889532690" />
                        </FormField>

                        <FormField>
                            <label>Enable</label>
                            <select name="enableWhatsAppNotif" disabled defaultValue="no" >
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </FormField>

                        <FormField>
                            <label>Upload Profile Avatar</label>
                            <input type="file" name="avatar" pattern="" multiple={false}/>
                        </FormField>
                    </PersonalDetailsContainer>
                    <br/>
                    <PersonalHeaderContainer>
                        <h4>Host Account Details</h4>
                    </PersonalHeaderContainer>

                    <HostDetailsContainer>
                        <FormField>
                            <label>Social Security Number (SSN)</label>
                            <input type="text" name="hostSSN" placeholder="9 digit SSN number" required />
                        </FormField>

                        <FormField>
                            <label>Individual TIN Number (ITIN)</label>
                            <input type="email" name="hostITIN" placeholder="9 digit ITIN number" />
                        </FormField>

                        <FormField>
                            <label>Payment Method</label>
                            <select name="hostPaymentMethod" defaultValue="Paypal">
                                <option value="Paypal">Paypal</option>
                                <option value="Stripe" disabled>Stripe</option>
                            </select>
                        </FormField>

                        <FormField>
                            <label>PayPal ID</label>
                            <input type="text" placeholder="PayPal ID / PayPal Email Address" required />
                        </FormField>                   
                    </HostDetailsContainer>

                    <ButtonContainer>
                        <button type="submit">Update</button>
                    </ButtonContainer>
                </form>
            </PersonalDetailContainer>
        </>
    )
}