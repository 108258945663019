import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { Newsletter } from "../components/Newsletter";
import { Famous } from "../components/Famous";
import Banner from "./../assets/banner.jpg"
import { AuthContext } from "../utils/context";
import { Link, useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import POOL_LOCATIONS from "./../gql/PoolLocations"
import {GrFormClose}  from "react-icons/gr"
import { motion } from "framer-motion";
import {useClickOutside} from "react-click-outside-hook"
import { MoonLoader } from "react-spinners";

const BannerSection = styled.section`
    ${tw`
        w-full
        h-[calc(100vh - 50px)]
        opacity-80
        object-cover
        items-center
        justify-center
        text-center

    `}
    background: url('${Banner}')
`
const SloganContainer = styled.div`
    ${tw`
        flex
        text-center
        items-center
        justify-center
        w-full
        text-4xl
        lg:text-6xl
        text-red-600
        font-Lobster
        p-2
        pt-[100px]
        md:pt-[150px]
        z-20
    `}
`

const ShadowCont = styled.div`
    ${tw`
        absolute
        bg-black
        opacity-20
        h-[calc(100vh - 50px)]
        w-full
        -z-1
    `}
`

const SearchContainer = styled.div`
    ${tw`
        grid
        lg:grid-cols-6
        md:grid-cols-2
        grid-cols-1
        w-full
        lg:w-[80%]
        mx-auto
        lg:bg-blue-800
        lg:opacity-[95%]
        lg:px-[50px]
        px-2
        lg:p-2
        lg:rounded-full
        md:px-16

    `}
`
const DateContainer = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-center
        lg:col-span-1
        md:col-span-1
        col-span-3
        /*bg-pink-400*/
        p-1
    `}
    input{
        ${tw`
            w-full
            md:rounded-sm
            rounded-md
            p-1
            lg:p-2
            border-b-2
            border-gray-200
            outline-none
            focus:border-red-600
        `}
    }
`

const FromTimeContainer = styled.div`
    ${tw`
        lg:col-span-2
        md:col-span-1
        col-span-2
        flex
        items-center
        justify-center
        text-center
        /*bg-yellow-400*/
        p-1
        gap-2
    `}
    input{
        ${tw`
            w-full
            md:rounded-sm
            rounded-md
            p-1
            lg:p-2
            outline-none
            border-b-2
            border-gray-200
            focus:border-red-600
        `}
    }
`

const SearchButtonContainer = styled.div`
    ${tw`
        w-full
        px-3
        mt-10
    `}

    button{
        ${tw`
            w-full
            md:w-[625px]
            lg:w-[400px]
            bg-red-600
            p-2
            text-white
            uppercase
            rounded-md
        `}
    }

`
const FreqOptionContainer = styled.div`
    ${tw`
        my-5
        flex
        w-[300px]
        mx-auto
        p-2
        px-2
        bg-white
        items-center
        justify-center
        text-center
        gap-2
        uppercase
        text-blue-800
        font-medium
        rounded-md
        text-xs
        md:text-sm
        z-20
    `}
`

const SearchBarContainer = styled.div`
    ${tw`
        flex
        w-full
        bg-white
        overflow-x-hidden
        col-span-3
        items-center
        text-center
        justify-center
        h-[40px]
        lg:h-[42px]
        my-1
        lg:rounded-sm
        rounded-md
        z-10
    `}
`

const SearchInputContainer = styled.div`
    ${tw`
        w-full
        flex
        text-center
        items-center
        relative
        py-1
        pr-10
        pl-2

    `}
`

const SearchInput = styled.input`
    ${tw`
        w-full
        outline-none
        border-0
        bg-[transparent]
    `}

    &: focus{
        ${tw`
            outline-none
        `}
        &::placeholder{
            ${tw`
                opacity-0
            `}
        }
    }

    &::placeholder{
        ${tw`
            text-gray-200
            transition-all
            ease-in-out
            transition-[250ms]
        `}
    }
`

const CloseIcon = styled.span`
    ${tw`
        text-gray-500
        text-sm
        mr-[5px]
        items-center
        transition-all
        transition-[250ms]
        ease-in-out
        cursor-pointer
        hover:text-gray-300
    `}
`
const LocationCont = styled(motion.div)`
    ${tw`
        w-[90%]
        lg:w-[70%]
        md:w-[82%]
        mx-auto
        // h-[25px]
        bg-gray-200
        ml-[calc(50% - 45%)]
        lg:ml-[calc(50% - 35%)]
        md:ml-[calc(50% - 41%)]
        absolute
        h-[0px]
        -mt-[90px]
        md:-mt-[45px]
        lg:-mt-[0px]
    `}
`

const containerVariants = {
    expanded:{
        height:"auto",
        'max-height':"12em",
        'overflow-y':"scroll"
    },
    collapsed:{
        height:"0px",
    }
}

const Form = styled.form`
    ${tw`
        relative
    `}
`

const LoadingWrapper = styled.div`
    ${tw`
        flex
        w-full
        h-full
        items-center
        text-center
        justify-center
        relative
    `}
`

const CityList = styled.div`
    ${tw`
        flex
        flex-col
        items-start
        text-left
        justify-start
        w-full
        p-2
        relative
    `}
`

const LoactionItem = styled.div`
    ${tw`
        flex
        flex-col
        w-full
        border-b
        border-gray-300
        py-1
    `}
    a{
        ${tw`
            z-[9999]
        `}
    }
`

export function Home(){
    const [frequency, setFrequency] = useState('single');
    const [noMultiple, setNoMultiple] = useState(true);
    const [formData, setFormData] = useState({});
    const UserAuth = useContext(AuthContext)

    const [isExpanded, setExpanded] = useState(false)
    const [ref, isClickedOutside] = useClickOutside();
    const inputRef = useRef();

    const [locationData, setLocationData] = useState(null)

    const expandContainer = () => {
        setExpanded(true);
        setLocationData(null)
    }

    const handleCopyLocation = (e) =>{
        e.preventDefault();
        //console.log(e.target.textContent)
        inputRef.current.value = e.target.textContent
        setFormData({
            ...formData,
            'destination':e.target.textContent
        })

        collapseContainer();
    }

    const collapseContainer = () => {
        setExpanded(false);
        if(!inputRef.current){
            inputRef.current.value = ""; 
        }

        setLocationData(null)
    }


    const [getPoolLocation,{loading, data}] = useLazyQuery(POOL_LOCATIONS)


    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        });

        if(e.target.name === "destination" && e.target.value.length >=3){
            getPoolLocation({
                variables:{
                    filters:{
                        City:{
                            contains:`${e.target.value}`
                        }
                    }
                }
            })
        }
    }

    const handleSearch = (e) =>{
        e.preventDefault()
        //console.log(formData)
    }

    const handleFreqRadio = (e) =>{
        setFrequency(e.target.value)
    }

    useEffect(() => {
        if(isClickedOutside){
            setLocationData(null)
        }

        if(isExpanded){
            setLocationData(data)
        }else{
            setLocationData(null)
        }
    },[isClickedOutside, data, isExpanded])

    return(
        <>
            <Header />
            <BannerSection>
                <ShadowCont></ShadowCont>
                <SloganContainer>
                    Book a day in Swimming Pool
                </SloganContainer>
                
                <Form onSubmit={handleSearch}>
                    
                    <FreqOptionContainer>
                        <input type="radio" value="single" name="freqOpt" checked={frequency === 'single'} onChange={handleFreqRadio} /> Single Day
                        <input type="radio" value="multiple" name="freqOpt" checked={frequency === 'multiple'} disabled={noMultiple} onChange={() => setFrequency('multiple')} /> Multiple days
                    </FreqOptionContainer>
                    
                    <SearchContainer>
                        <SearchBarContainer>
                            <SearchInputContainer ref={ref}>
                                <SearchInput name="destination" placeholder="Enter 2 characters of your city..." onFocus={expandContainer} ref={inputRef} onChange={handleChange} autoComplete="off"  />
                            </SearchInputContainer>
                            <CloseIcon>
                                <GrFormClose size={20} onClick={collapseContainer} />
                            </CloseIcon>
                        </SearchBarContainer>
                        <DateContainer>
                            <input type="date" name="bookingDate" placeholder="Date" onChange={handleChange}></input>
                        </DateContainer>

                        <FromTimeContainer>
                            <input type="time" name="fromTime" onChange={handleChange} ></input>
                            <input type="time" name="tillTime" onChange={handleChange}></input>
                        </FromTimeContainer>
                    </SearchContainer>

                    <LocationCont animate={isExpanded ? "expanded" : "collapsed"} variants={containerVariants}>
                        {
                            loading && 
                            <LoadingWrapper>
                                <MoonLoader loading={loading} color="#000" size={20} />
                            </LoadingWrapper>
                        }

                        {
                            locationData &&
                            <CityList>
                                {
                                    locationData && 

                                    locationData.pools.data.map((Ct, i) => {
                                        return(
                                            <LoactionItem onClick={handleCopyLocation}>
                                                <Link >{`${Ct.attributes.City}, ${Ct.attributes.State}, ${Ct.attributes.Country}`}</Link>
                                            </LoactionItem>
                                        )
                                    })
                                }
                            </CityList>
                        }                        
                    </LocationCont>
                    <SearchButtonContainer>
                        <button type="search">Search</button>
                    </SearchButtonContainer>
                </Form>
            </BannerSection>

            <Famous />
            <Newsletter/>
            <Footer />
        </>
    )
}