import { gql } from "@apollo/client";

const GQL_NEW_POOL = gql`
    mutation CreatePool($data: PoolInput!) {
        createPool(data: $data) {
          data {
            id
          }
        }
    }
`

export default GQL_NEW_POOL;