import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import { Header } from "../components/Header";
import IMG1 from "./../assets/p1.png"
import {FaStripe, FaPaypal} from "react-icons/fa"
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Store } from 'react-notifications-component';


const PageContainer = styled.div`
    ${tw`
        flex
        flex-col
        lg:px-40
        px-2
        py-5
    `}
`

const PageTitle = styled.div`
    ${tw`
        flex
        text-xl
        items-center
        justify-start
        text-center
        lg:gap-5
        gap-2
        font-bold
    `}
    span{
        ${tw`
            md:block
            hidden

        `}
    }
    
`

const BackContainer = styled.div`
    ${tw`
        hover:bg-gray-400 
        hover:text-white
        transition-all
        duration-200
        flex
        items-center
        text-center
        justify-center
        h-8
        w-8
        bg-gray-200 
        rounded-full
    `}
    a{
        ${tw`
            -mt-[5px]
            text-2xl
            
        `}
    }
`

const BookingContainer = styled.div`
    ${tw`
        lg:mt-10
        lg:px-10
        px-2
        grid
        lg:grid-cols-2
        grid-cols-1
        gap-2
    `}
`

const SubTitle = styled.div`
    ${tw`
        font-semibold
        text-xl
    `}
`

const BookingDetails = styled.div`
    ${tw`
        /*bg-blue-300*/
        flex
        flex-col
        p-2
        py-5
        col-span-1
        order-1
        lg:order-none
    `}
`
const BookingDates = styled.div`
    ${tw`
        flex
        flex-col
        border-b-2
        border-gray-300
        pb-5 
    `}
`

const DateContainer = styled.div`
    ${tw`
        flex
        flex-col
        items-center
        justify-between
        text-center
        gap-2
    `}
` 

const FormField = styled.div`
    ${tw`
        flex
        flex-col
        w-full
        items-start
        text-left
        justify-start
    `}
    input{
        ${tw`
            w-full
            h-8
            border
            border-gray-200
        `}
    }
`

const PaywithContainer = styled.div`
    ${tw`
        flex
        flex-col
        mt-2
    `}
`

const PayWithTitle = styled.div`
    ${tw`
        flex
        items-center
        justify-between
    `}
`

const Title = styled.div`
    ${tw`
        flex
        text-xl
        font-semibold
    `}
`
const PayIcons = styled.div`
    ${tw`
        flex
        items-center
        justify-center
        text-center
        gap-1
    `}
`

const PayOptions = styled.div`
    ${tw`
        
    `}
    select{
        ${tw`
            w-full
            h-10
        `}
    }
`

const BookButtonContainer = styled.div`
    ${tw`
        flex
        mt-5
    `}
    button{
        ${tw`
            bg-red-600
            text-white
            p-2
            rounded-md
            md:w-[150px]
            w-full
        `}
    }
`

const BookingPrice = styled.div`
    ${tw`
        /*bg-yellow-400*/
        border-2
        border-gray-300
        p-2
        md:p-5
        rounded-lg
        col-span-1
    `}
`

const PoolInfoContainer = styled.div`
    ${tw`
        flex
        text-center
        items-start
        justify-start
        gap-3
        grid
    `}
`
const PoolImage = styled.img`
    ${tw`
        rounded-md
        md:h-40
        object-cover
    `}
`

const PoolDet = styled.div`
    ${tw`
        flex
        flex-col
    `}
`
const PoolName = styled.div`
    ${tw`
        flex
        text-xl
        text-red-300
    `}
`
const PoolAddress = styled.div`
    ${tw`
        flex
        text-xs
        text-blue-800
        items-center
        text-left
        justify-start
        mt-3
    `}
`

const PriceDetails = styled.div`
    ${tw`
        flex
        flex-col
        mt-5
        border-t
        border-b
        py-2
    `}
`

const PriceTitle = styled.div`
    ${tw`
        flex
        text-lg
        font-semibold
    `}
`

const BillType = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-between
        p-1
    `}
`
const Rate = styled.div`
    ${tw`
        flex
        text-base
    `}
`
const RateTotal = styled.div`
    ${tw`
        flex
        text-base
    `}
`

const TotalSection = styled.div`
    ${tw`
        font-bold

    `}
`

const PayPalContainer = styled.div`
    ${tw`
        flex
        w-full
    `}
    div{
        ${tw`
            w-full
        `}
    }
`
export function BookNow(){

    const [payOption, setPayOption] = useState(null);

    const handleOptionChange = (e) => {
        e.preventDefault()
        //console.log(e.target.value)
        setPayOption(e.target.value)
    }

    return(
        <>
            <Header />
            
            <PageContainer>
                <PageTitle>
                    <BackContainer>
                        <Link> &#60;</Link>
                    </BackContainer>
                    <span>Request to book</span>
                </PageTitle>
                <BookingContainer>
                    <BookingDetails>
                        <SubTitle>Your booking</SubTitle>
                        <BookingDates>
                            <DateContainer>
                                <FormField>
                                    <label>Date</label>
                                    <input type="date"></input>
                                </FormField>
                                <FormField>
                                    <label>Start Time</label>
                                    <input type="time"></input>
                                </FormField>
                                <FormField>
                                    <label>End Time</label>
                                    <input type="time"></input>
                                </FormField>
                                <FormField>
                                    <label>Guests</label>
                                    <input type="number" min="1"></input>
                                </FormField>
                            </DateContainer>
                        </BookingDates>
                        <PaywithContainer>
                            <PayWithTitle>
                                <Title>Pay with</Title>
                                <PayIcons>
                                    <FaStripe size={30} color="#0096FF" />
                                    <FaPaypal size={20} color="#0047AB" />
                                </PayIcons>
                            </PayWithTitle>
                            <PayOptions>
                                <select onChange={handleOptionChange}>
                                    <option disabled>Choose Payment Method</option>
                                    <option>Paypal</option>
                                    <option>Stripe</option>
                                </select>
                            </PayOptions>

                            <BookButtonContainer>
                                {
                                    payOption === "Stripe" 
                                        ? 
                                            <div>Option Stripe</div> 
                                        :  
                                            <PayPalContainer>

                                                <PayPalScriptProvider options={{"client-id": "AWaWi1kpK-viFgQef7WkZHm9Gj7vzVjNUNkkcl6XMLBhCVRAmG3x4z1UeVS1UFRY_m28LPqC-S8-8RHu"}}>
                                                    <PayPalButtons 
                                                        createOrder={(data,actions) =>{
                                                            return actions.order.create({
                                                                purchase_units:[
                                                                    {
                                                                        amount:{
                                                                            value: "6.54",
                                                                            currency_code: "USD"
                                                                        },
                                                                    }
                                                                ]
                                                            })
                                                        }}

                                                        onApprove={(data,actions) =>{
                                                            return actions.order.capture().then(function(details){
                                                                //alert("Transaction completed by" + details.payer.name.given_name)
                                                                Store.addNotification({
                                                                    title: "Booking Successfull",
                                                                    message: "Your pool has been booked",
                                                                    type: "success",
                                                                    insert: "top",
                                                                    container: "top-center",
                                                                    animationIn: ["animate__animated", "animate__fadeInDown"],
                                                                    animationOut: ["animate__animated", "animate__fadeOutUp"],
                                                                    dismiss: {
                                                                      duration: 3000,
                                                                      onScreen: true
                                                                    }
                                                                });
                                                                  
                                                            })
                                                        }}

                                                        style={{
                                                            height:45
                                                        }}
                                                        />
                                                </PayPalScriptProvider>
                                            </PayPalContainer>
                                } 
                                {/**<button type="submit">Request to book</button>*/}
                            </BookButtonContainer>
                            
                        </PaywithContainer>
                    </BookingDetails> 
                    <BookingPrice>
                        <PoolInfoContainer>
                            <PoolImage src={IMG1} alt="" />
                            <PoolDet>
                                <PoolName>
                                    St Valley Pool
                                </PoolName>
                                <PoolAddress>
                                    Keas 69 Str. 15234, Chalandri Athens, Greece +30-2106019311 (landline) +30-6977664062
                                </PoolAddress>
                            </PoolDet>
                        </PoolInfoContainer>
                        <PriceDetails>
                            <PriceTitle>Price Details</PriceTitle>
                            <BillType>
                                <Rate>₹2,400 x 23 nights</Rate>
                                <RateTotal>₹55,200</RateTotal>
                            </BillType>

                            <BillType>
                                <Rate>Discount</Rate>
                                <RateTotal>-₹6,624</RateTotal>
                            </BillType>

                            <BillType>
                                <Rate>Service Fee</Rate>
                                <RateTotal>₹6,857.81</RateTotal>
                            </BillType>

                            <BillType>
                                <Rate>Taxes</Rate>
                                <RateTotal>₹5,829.12</RateTotal>
                            </BillType>
                        </PriceDetails>

                        <TotalSection>
                            <BillType>
                                <Rate>Total (INR)</Rate>
                                <RateTotal>₹61,262.93</RateTotal>
                            </BillType>
                        </TotalSection>
                    </BookingPrice> 
                </BookingContainer>
            </PageContainer>
        </>
    )
}