import { gql } from "@apollo/client";

const SINGLE_POOL = gql`
  query Pools($filters: PoolFiltersInput) {
    pools(filters: $filters) {
      data {
        id
        attributes {
          name
          description
          cover {
            data {
              id
              attributes {
                name
                url
              }
            }
          }
          Active
          City
          State
          Country
          rate
          rating
          createdAt
          updatedAt
        }
      }
    }
  }
`

export default SINGLE_POOL;