import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import LoginBG from "./../assets/bg2.jpg"
import { AuthContext } from "../utils/context";
import USER_LOGIN from "../gql/UserLogin";
import { useMutation } from "@apollo/client";


const LoginPage = styled.div`
    ${tw`
        flex
        w-full
        h-[100vh]
        object-fill
        relative
    `}
`

const BGOverlay = styled.div`
    ${tw`
        absolute
        w-[100vw]
        h-[100vh]
        bg-black
        opacity-50
        z-1
    `}
`

const LoginContainer = styled.div`
    ${tw`
        flex
        flex-col
        w-full
        items-center
        text-center
        justify-center
        z-10

    `}
`

const LogoContainer = styled.div`
    ${tw`
        flex
        text-center
        items-center
        justify-center
        font-Raleway
        text-4xl
        md:text-6xl
        text-white
    `}
`

const LoginFormContainer = styled.div`
    ${tw`
        flex
        flex-col
        bg-white
        w-[300px]
        md:w-[350px]
        h-auto
        p-6
        md:p-10
        mt-5
        rounded-md
    `}
`

const LoginHeader = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-center
        uppercase
        text-lg
        text-blue-800
    `}
`

const FormField = styled.div`
    ${tw`
        flex
        flex-col
        items-start
        justify-start
        text-left
    `}
    label{
        ${tw`
            w-full
            text-blue-800
        `}
    }
    input{
        ${tw`
            w-full
            p-2
            border
            border-blue-800
            rounded-sm
            outline-none
            focus:border-2
            focus:border-blue-800

        `}
    }
`

const LoginButtonContainer = styled.div`
    ${tw`
        flex
        w-full
        text-center
        items-center
        justify-center
        mt-3
        
    `}
    button{
        ${tw`
            p-2
            border-2
            border-blue-800
            w-full
            bg-blue-800
            text-white
            uppercase
            hover:border-2
            hover:border-blue-200
            hover:bg-blue-200
            hover:text-blue-800
            transition-all
            duration-200
            ease-in-out
        `}
    }
`

const ResetPasswordContainer = styled.div`
    ${tw`
        flex
        text-xs
        mt-2
        text-blue-800
        underline
        justify-between
        text-center
        items-center
        
    `}
`



export function Login(){
    const [formData, setFormData] = useState({});
    const UserAuth = useContext(AuthContext)
    const auth  = UserAuth;
    const [userLogin,{loading,error, data}] = useMutation(USER_LOGIN);
    const navigate = useNavigate();

    const handleChange = (e) =>{
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })   
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        userLogin({
            variables:{
                input: formData
            }
        }).then(res =>{
            if(res){
                //console.log(res.data)
                auth.login(res.data)
            }
        })
        
    }

    useEffect(()=>{
        if(data){
            navigate("/", {replace: true})
        }else{
            console.log(error)
        }
    },[data, error, navigate])

    return(
        <LoginPage style={{backgroundImage : `url(${LoginBG})`}}>
            <BGOverlay />
            <LoginContainer>
                <LogoContainer>Swim</LogoContainer>
                <LoginFormContainer>
                    <LoginHeader>
                        Login
                    </LoginHeader>
                    <form onSubmit={handleSubmit}>
                        <FormField>
                            <label>Username</label>
                            <input type="text" name="identifier" placeholder="Username" onChange={handleChange} required />
                        </FormField>

                        <FormField>
                            <label>Password</label>
                            <input type="password" name="password" placeholder="Password" onChange={handleChange} required />
                        </FormField>

                        <LoginButtonContainer>
                            <button>Login</button>
                        </LoginButtonContainer>

                        <ResetPasswordContainer>
                            <Link to="/forget-password">Forget Password ?</Link>
                            <Link to="/register">Create new account</Link>
                        </ResetPasswordContainer>
                    </form>
                </LoginFormContainer>
            </LoginContainer>
        </LoginPage>        
    )
}