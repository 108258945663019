import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Header } from "../components/Header";
import { useQuery} from "@apollo/client";
import POOL_LIST from "../gql/PoolList";
import StarRatings from "react-star-ratings";
import { generatePath, Link, useNavigate, useParams } from "react-router-dom";

const PageContainer = styled.div`
    ${tw`
        flex
        flex-col
        items-center
        text-center
        justify-center
    `}
`

const SearchContainer = styled.div`
    ${tw`
        bg-blue-800
        w-full
        lg:mt-5
        lg:w-[80%]
        grid
        grid-cols-1
        md:grid-cols-3
        lg:grid-cols-6
        p-2
        gap-2        
    `}
`

const SearchBoxContainer = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-center
        md:col-span-2
        lg:col-span-2
        col-span-1
    `}
    input{
        ${tw`
            w-full
            p-[9px]
        `}
    }
`

const DateContainer = styled.div`
    ${tw`
        items-center
        text-center
        justify-center
        col-span-1
    `}
    input{
        ${tw`
            w-full
            p-2
        `}
    }
`

const TimeContainer = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-center
        w-full
        col-span-1
    `}
    input{
        ${tw`
            p-2
            w-full
        `}
    }
`

const ButtonContainer = styled.div`
    ${tw`
        text-white
        col-span-1
    `}

    button{
        ${tw`
            p-2
            uppercase
            w-full
        `}
    }
`

const ShowingSection = styled.div`
    ${tw`
        flex
        w-[95%]
        mt-5
        md:mt-5
        border-b
        text-sm
        text-blue-800
        p-2
        lg:px-5
        gap-1
    `}
`

const Count = styled.span`
    ${tw`
        font-bold
    `}
`

const PoolListContainer = styled.div`
    ${tw`
        grid
        grid-cols-1
        md:grid-cols-3
        lg:grid-cols-5
        p-3
        md:p-5
        w-full
        gap-3
        lg:gap-5
    `}
`

const PoolCardContainer = styled.div`
    ${tw`
        col-span-1
        flex-col
        gap-2
        relative
    `}
`

const PoolCard = styled.div`
    ${tw`
        flex-col
        w-full
        relative
    `}
    img{
        ${tw`
            h-full
            w-full
            object-cover
            rounded-md
            cursor-pointer
        `}
        box-shadow:0px 0px 5px black
    }

`

const PoolDetails = styled.div`
    ${tw`
        flex
        flex-col
        text-blue-800
        font-Raleway
        p-2
    `}
`

const PoolNameContainer = styled.div`
    ${tw`
        flex
        flex-col
        w-full
        sm:text-xs
        md:text-xs
        lg:text-base
        text-center
        justify-between
        items-start
    `}
    a{
        ${tw`
            text-red-400
            font-bold
        `}
    }
`

const PoolRatingContainer = styled.div`
    ${tw`
        flex
        gap-1
        text-yellow-300
    `}
`





export function Pools(){
    const [productID,setProductID] = useState()   
    const {loading, error, data} = useQuery(POOL_LIST)
    const navigate = useNavigate();

    console.log(productID);
    if(data){
        console.log(data)
    }

    return(
        <>
            <Header />

            <PageContainer>
                <SearchContainer>
                    <SearchBoxContainer>
                        <input type="search" name="poolSearch" placeholder="Location..."/>
                    </SearchBoxContainer>
                    <DateContainer>
                        <input type="date" name="bookingDate"/>
                    </DateContainer>
                    <TimeContainer>
                        <input type="time" name="fromTime" />
                    </TimeContainer>
                    
                    <TimeContainer>
                        <input type="time" name="tillTime" />
                    </TimeContainer>

                    <ButtonContainer>
                        <button type="search" name='searchpool'>Search</button>
                    </ButtonContainer>
                </SearchContainer>

                <PoolListContainer>
                    {
                        data &&
                        data.pools.data.map(pool => (
                            <PoolCardContainer key={pool.id}>
                                <PoolCard>
                                    <img src={"http://localhost:1337"+ pool.attributes.cover.data.attributes.url} alt={pool.attributes.cover.data.attributes.name} />
                                    <PoolDetails>
                                        <PoolNameContainer>
                                            <Link to={`/pool/${pool.id}`}>{pool.attributes.name}</Link>
                                            <span>Rate: ${pool.attributes.rate} /hr</span>
                                        </PoolNameContainer>
                                        <PoolRatingContainer>
                                            <StarRatings rating={pool.attributes.rating} starRatedColor="red" starEmptyColor="grey" starDimension="20px" starSpacing="5px"/>
                                        </PoolRatingContainer>
                                    </PoolDetails>
                                </PoolCard>
                            </PoolCardContainer>    
                        ))
                    }
                    
                </PoolListContainer>
            </PageContainer>
            
        </>
    )
}