import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import StarRatings from "react-star-ratings";
import styled from "styled-components";
import tw from "twin.macro";
import { Header } from "../components/Header";
import SINGLE_POOL from "../gql/SinglePool";
import PoolImg from "./../assets/p1.png"
import {HiLocationMarker} from "react-icons/hi"
import {BsDropletHalf} from "react-icons/bs"
import {MdChildCare, MdPets} from "react-icons/md"
import {FaGlassMartiniAlt} from "react-icons/fa"
import {GiLockers} from "react-icons/gi"

const PageContainer = styled.div`
    ${tw`
        grid
        grid-cols-1
        md:grid-cols-3 mt-3
        px-3
        lg:px-[200px]
        lg:py-10
    `}
`

const PoolImageContainer = styled.div`
    ${tw`
       col-span-1
       /*bg-blue-400*/
       p-1
       flex
       items-center
       text-center
       justify-center
    `}
    img{
        box-shadow:0px 0px 8px black;
        border-radius:5px;
        ${tw`
            w-full
            h-full
            object-cover
        `}
    }
`

const PoolDetailContainer = styled.div`
    ${tw`
        /*bg-red-200*/
        col-span-1
        md:col-span-2
        px-2
        md:px-10
        flex
        flex-col
        w-full
    `}
`

const PoolName = styled.div`
    ${tw`
        text-red-600
        font-semibold
        text-2xl
        font-Raleway
        flex
        
    `}
`

const PoolLocation = styled.div`
    ${tw`
        flex
        text-blue-800
        text-sm
    `}
`

const PoolRatingContainer = styled.div`
    ${tw`
        flex
        items-center
    `}
`

const RatingCount = styled.span`
    ${tw`
        text-amber-600
        font-semibold
        mt-[2px]
        ml-5
    `}
`
const AboutPoolContainer = styled.div`
    ${tw`
        flex
        flex-col
        mt-2
    `}

`

const HeadingContainer = styled.div`
    ${tw`
        flex
        w-full
        font-semibold
        text-blue-800
        underline
    `}
`
const PoolDescContainer = styled.div`
    ${tw`
        flex
        md:text-xs
        text-left
        items-center
        justify-start
    `}
`

const PoolAmenities = styled.div`
    ${tw`
        flex
        flex-col
        w-full
        mt-5
        col-span-3
    `}
`

const AmentiesListContainer = styled.div`
    ${tw`
        grid
        grid-cols-1
        md:grid-cols-2
        lg:grid-cols-4
        w-full
        mt-1
        gap-5
    `}
`

const AmenitiesListItem = styled.div`
    ${tw`
        col-span-1
        flex
        text-center
        items-center
        justify-start
        text-sm
        gap-5
        p-2
        rounded-sm
    `}
    box-shadow:0px 0px 5px silver    
`

const AmenitiesLogo = styled.div`
    ${tw`
        flex
        text-center
        items-center
        justify-center
        border-r-2
        p-2
    `}
`
const AmenitiesItemDetails = styled.div`
    ${tw`
        flex
        flex-col
    `}
`

const ItemTitle = styled.h3`
    ${tw`
        flex
        font-bold
        text-lg
        text-gray-600
        text-left
        items-center
        justify-start
        w-full
    `}
`
const ItemDesc = styled.div`
    ${tw`
        text-sm
        text-gray-600
        w-full
        flex
        justify-start
    `}
`

const PremiumServices = styled.div`
    ${tw`
        flex
        flex-col
        w-full
        mt-5
        col-span-3 
    `}
`
const PremiumServiceItemCont = styled.div`
    ${tw`
        grid
        grid-cols-1
        md:grid-cols-2
        lg:grid-cols-4
        w-full
        mt-1
        gap-5
    `}
`

const ReviewSection = styled.div`
    ${tw`
        flex
        flex-col
        w-full
        mt-5
        col-span-3
    `}
`

const BookNow = styled.div`
    ${tw`
        mt-5
        flex
    `}
    a{
        ${tw`
            text-white
            bg-red-600
            p-2
            uppercase
            text-sm
            md:w-[100px]
            w-full
            text-center
        `}
    }
`

export function PoolDetail(){
    const {id} = useParams();
    const [poolID, setPoolID] = useState(0);

    useEffect(() => {
        setPoolID(id)
        console.log(poolID)
    },[id,poolID])

    

    return(
        <>
            <Header />
            
            <PageContainer>
                <PoolImageContainer>
                    <img src={PoolImg} alt="" />                   
                </PoolImageContainer>
                <PoolDetailContainer>
                    <PoolName>St Valley Pool</PoolName>
                    <PoolLocation>
                        <HiLocationMarker size={20} />
                        <p>
                            Keas 69 Str. 15234, Chalandri Athens, Greece +30-2106019311 (landline) +30-6977664062
                        </p>
                    </PoolLocation>
                    <PoolRatingContainer>
                        <StarRatings rating={3.5} starDimension="20" starSpacing="5px" starRatedColor="red" />
                        <RatingCount>(3.5)</RatingCount>
                    </PoolRatingContainer>
                    <AboutPoolContainer>
                        <HeadingContainer>About the pool</HeadingContainer>
                        <PoolDescContainer>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                            dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen 
                            book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially 
                            unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and 
                            more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </PoolDescContainer>
                    </AboutPoolContainer>

                    <BookNow>
                        <Link to={`/book-now/${1}`}>Book Now</Link>
                    </BookNow>
                </PoolDetailContainer>
                <PoolAmenities>
                    <HeadingContainer>
                        Amenities
                    </HeadingContainer>
                    <AmentiesListContainer>
                        <AmenitiesListItem>
                            <AmenitiesLogo>
                                <BsDropletHalf size={40} />
                            </AmenitiesLogo>
                            <AmenitiesItemDetails>
                                <ItemTitle>Chlorine</ItemTitle>
                                <ItemDesc>Chlorine free healthy water</ItemDesc>
                            </AmenitiesItemDetails>
                        </AmenitiesListItem>

                        <AmenitiesListItem>
                            <AmenitiesLogo>
                                <MdChildCare size={40} />
                            </AmenitiesLogo>
                            <AmenitiesItemDetails>
                                <ItemTitle>Children friendly</ItemTitle>
                                <ItemDesc>Safe for children and infants</ItemDesc>
                            </AmenitiesItemDetails>
                        </AmenitiesListItem>

                        <AmenitiesListItem>
                            <AmenitiesLogo>
                                <MdPets size={40} />
                            </AmenitiesLogo>
                            <AmenitiesItemDetails>
                                <ItemTitle>Pet friendly</ItemTitle>
                                <ItemDesc>Safe place for your pets</ItemDesc>
                            </AmenitiesItemDetails>
                        </AmenitiesListItem>
                    </AmentiesListContainer>
                </PoolAmenities>

                <PremiumServices>
                    <HeadingContainer>
                        Premium Services
                    </HeadingContainer>
                    <PremiumServiceItemCont>

                        <AmenitiesListItem>
                            <AmenitiesLogo>
                                <FaGlassMartiniAlt size={40} />
                            </AmenitiesLogo>
                            <AmenitiesItemDetails>
                                <ItemTitle>Restaurant and Bar</ItemTitle>
                                <ItemDesc>Paid</ItemDesc>
                            </AmenitiesItemDetails>
                        </AmenitiesListItem>

                        <AmenitiesListItem>
                            <AmenitiesLogo>
                                <GiLockers size={40} />
                            </AmenitiesLogo>
                            <AmenitiesItemDetails>
                                <ItemTitle>Personal Lockers</ItemTitle>
                                <ItemDesc>$2.00 /hr</ItemDesc>
                            </AmenitiesItemDetails>
                        </AmenitiesListItem>               
                    </PremiumServiceItemCont>

                    <ReviewSection>
                        <HeadingContainer>
                        </HeadingContainer>
                    </ReviewSection>
                </PremiumServices>
            </PageContainer>           
        </>
    )
}