import {gql}  from "@apollo/client";

const POOL_LIST = gql`
    query Pools($sort: [String]) {
        pools(sort: $sort) {
          data {
            id
            attributes {
              name
              City
              State
              Country
              rate
              Active
              rating
              capacity
              childrenAllowed
              createdAt
              updatedAt
              cover {
                data {
                  id
                  attributes {
                    name
                    url
                  }
                }
              }
            }
          }
        }
    }
`

export default POOL_LIST;