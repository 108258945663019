import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../utils/context";

export function ProtectedRoutes({children}){
    const UserAuth = useContext(AuthContext);
    const {user} = UserAuth

    const tk = localStorage.getItem('token');

    if(!tk){
        return <Navigate to="/login" />
    }
    return(
        children
    )
}