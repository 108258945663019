import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const NewsletterContainer = styled.section`
    ${tw`
        flex-col
        bg-white
        text-center
        items-center
        justify-center
        py-8
        w-full
    `}
`

const Header = styled.h4`
    ${tw`
        font-Raleway
        text-lg
        text-blue-800
        font-semibold
    `}
`

const NewsLetterText = styled.span`
    ${tw`
        text-base
        lg:text-xl
        text-red-500
    `}
`

const EmailContainer = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-center
        gap-2
        md:w-[80%]
        lg:w-[40%]
        bg-red-500
        mx-auto
        mt-2
        p-2
        text-xs
        md:text-base
    `}
    input{
        ${tw`
            outline-none
            w-full
            p-2
        `}
    }

    button{
        ${tw`
            uppercase
            text-white
            p-2
        `}
    }
`


export function Newsletter(){
    return(
        <NewsletterContainer>
            <Header>Newsletter</Header>
            <NewsLetterText>Sign up for latest news and exciting offers</NewsLetterText>
            <EmailContainer>
                <input type="email" name="newletter_email" placeholder="Enter your email to subscribe"></input>
                <button type="submit" name="newsletter_subscribe">Subscribe</button>
            </EmailContainer>
        </NewsletterContainer>

    )
}