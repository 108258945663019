import { gql } from "@apollo/client";

const POOL_LOCATIONS = gql`
query Query($filters: PoolFiltersInput) {
  pools(filters: $filters) {
    data {
      attributes {
        City
        State
        Country
      }
    }
  }
}
`
export default POOL_LOCATIONS;