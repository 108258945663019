import React, { useContext } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {BsCart2} from "react-icons/bs"
import {GiHamburgerMenu} from "react-icons/gi"
import { Link, NavLink, useNavigate } from "react-router-dom";
import AvatarImg from "./../assets/avatar/mouni.jpg"
import { AuthContext } from "../utils/context";

const HeaderContainer = styled.div`
    ${tw`
        flex
        w-full
        bg-black
        items-center
        justify-center
        text-center
        text-white
        bg-black
    `}
`

const HeaderContent = styled.div`
    ${tw`
        flex
        items-center
        justify-between
        text-center
        w-[90%]
        p-2
    `}
`

const LogoContainer = styled.div`
    ${tw`
        flex
        text-center
        items-center
        justify-center
        font-Raleway
        text-2xl
        font-semibold
        text-white
        gap-2
    `}
    svg{
        ${tw`
            lg:hidden
        `}
    }
`

const NavContainer = styled.nav`
    ${tw`
        block
        hidden
        lg:flex
        gap-2
    `}

    img{
        ${tw`
            w-[30px]
            h-[30px]
            rounded-[100%]
            border
            border-white
            object-cover
            mx-2
            cursor-pointer
        `}
    }
`


const CartContainer = styled.div`
    ${tw`
        relative
        mt-1
    `}
`

const CartItemCont = styled.span`
    ${tw`
        absolute
        flex
        -top-2
        -right-2
        bg-blue-800
        text-[10px]
        text-white
        h-[14px]
        w-[14px]
        text-center
        items-center
        justify-center
        p-2
        font-bold
    `}
    border-radius: 100%
`


const UserName = styled.span`
    ${tw`
        text-base
        text-red-600
    `}
`

export function Header(){
    const auth = useContext(AuthContext)
    const navigate = useNavigate();
    const {userDet} = auth
    

    const navLinkStyles = ({isActive}) => {
        return{
            "color": isActive ? 'rgb(59 130 246)' : 'white',
            fontWeight: isActive ? 'bolder' : 'normal',
        }
    }
    
    const handleCartClick = (e) => {
        e.preventDefault();
        console.log("Cart")
    }

    const handleSignout = (e) => {
        e.preventDefault();
        auth.logout();
        navigate("/login", {replace: true})
        
    }

    const tk = localStorage.getItem('token')
    
    return(
        <HeaderContainer>
            <HeaderContent >
                <LogoContainer>
                    <GiHamburgerMenu />
                    <Link to="/">Swim</Link>
                </LogoContainer>

                <NavContainer>
                    {
                        tk &&
                        <>
                            <NavLink style={navLinkStyles} to='/'>Home</NavLink>
                            <NavLink style={navLinkStyles} to='/become-host'>Become Host</NavLink>
                            <NavLink style={navLinkStyles} to='/myaccount'>My Account</NavLink>
                        </>
                    }
                    
                    {
                        !tk && 
                            <>
                                <NavLink style={navLinkStyles} to='/login'>Login</NavLink>
                                <NavLink style={navLinkStyles} to='/register'>Register</NavLink>
                            </>
                    }
                    {
                        tk && <NavLink style={navLinkStyles} to='/logout' onClick={handleSignout}>Logout</NavLink>
                    }
                    
                    {
                        tk &&
                        <>
                            {/* <UserName>{userDet.user.username}</UserName> */}
                            <img src={AvatarImg} alt="" />
                            <NavLink onClick={handleCartClick}>
                                <CartContainer>
                                    <BsCart2 size={17} />
                                    <CartItemCont>2</CartItemCont>
                                </CartContainer>
                            </NavLink>
                        </>
                    }

                    {/*<NavList>
                        <NavItem>Home</NavItem>
                        <NavItem>Become A Host</NavItem>
                        <NavItem>Login</NavItem>
                        <NavItem>Resgister</NavItem>
                        <NavItem>
                            <CartContainer>
                                <BsCart2 size={17} />
                                <CartItemCont>2</CartItemCont>
                            </CartContainer>
                        </NavItem>
                    </NavList>*/}
                    
                </NavContainer>
            </HeaderContent>
        </HeaderContainer>
    )
}