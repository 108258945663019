import { useQuery } from "@apollo/client";
import React from "react";
import { GoKebabVertical } from "react-icons/go";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import POOL_LIST from "../gql/PoolList";

const PageContainer = styled.div`
    ${tw`
        w-full
        p-10
    `}
`

const ActionContainer = styled.div`
    ${tw`
        w-full
        mb-2
        p-2
        flex
        gap-2
        items-center
        justify-end
    `}
    button{
        ${tw`
            bg-red-400
            px-4
            py-1
            rounded-full
        `}
    }
    select{
        ${tw`
            border
            border-gray-300
        `}
    }
`

const TableContainer = styled.div`
     ${tw`
        w-full
        box-border
        p-0
        m-0        
     `}
    table{
        ${tw`
            table-auto
            w-full
        `}
    }
    table td{
        ${tw`
            text-sm 
            px-3
            py-4
            text-left
        `}
    }

    table th{
        ${tw`
            text-sm 
            px-3
            py-4
            text-left
            border-2
            border-solid
            border-l-0
            border-r-0
            font-bold
        `}
    }

    table tbody tr{
        ${tw`
            hover:bg-gray-100
            border-b
            border-gray-200
        `}
    }

    table tbody tr td{
        ${tw`
            first:font-semibold
            first:text-blue-800
            first:underline
            first:hover:cursor-pointer
            first:flex
            first:items-center
            first:text-left
            first:justify-start
        `}
    }
`

export function MyPools(){
    const {loading, error, data} = useQuery(POOL_LIST,{
        context:{
            headers:{
                Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc3NDUwNDUyLCJleHAiOjE2ODAwNDI0NTJ9.edFo8LyE-qFp-q55XvkCqezdvXEmj2xvbg8vvNrKG9Y'
            }
        }
    })

    if(data){
        console.log(data)
    }

    return(
        
        <>
            <PageContainer>
                <ActionContainer>
                    <span>Actions</span>
                    <select>
                        <option selected disabled>Choose</option>
                        <option>Delete</option>
                        <option>Cancell</option>
                    </select>
                    <button>GO</button>
                </ActionContainer>

                {
                    loading && <div>Loading...</div>
                }

                {
                    error && <div>{error}</div>
                }


            {
                data &&
                <>
                    <TableContainer>
                    <table>
                            <thead>
                                <tr>
                                    <th><input type="checkbox" /></th>
                                    <th>ID</th>
                                    <th>Pool Name</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Country</th>
                                    <th>Rate</th>
                                    <th>Capacity</th>
                                    <th>Children</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.pools.data.map((pool) => {
                                        return(
                                            <tr key={pool.id}>
                                                <td><input type="checkbox" /></td>
                                                <td>{pool.id}</td>
                                                <td>{pool.attributes.name}</td>
                                                <td>{pool.attributes.City}</td>
                                                <td>{pool.attributes.State}</td>
                                                <td>{pool.attributes.Country}</td>
                                                <td>{pool.attributes.rate}</td>
                                                <td>{pool.attributes.capacity}</td>
                                                <td>{pool.attributes.Status}</td>
                                                <td>{pool.attributes.childrenAllowed}</td>
                                                <td><Link><GoKebabVertical /></Link></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </TableContainer>
                </>
            }
            </PageContainer>
        </> 
    )
}