import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";

const PageContainer = styled.div`
    ${tw`
        w-full
        p-10
    `}
`

const Heading = styled.h2`
    ${tw`
        text-xl
        uppercase
        font-semibold
    `}
`

const PoolInfoContainer = styled.div`
    ${tw`
        grid
        grid-cols-1
        lg:grid-cols-2
        mt-5
    `}
`

const FormField = styled.div`
    ${tw`
        w-full
        flex
        flex-col
        items-start
        justify-start
        text-left
    `}
    label{
        ${tw`
            w-full
            text-blue-800
        `}
    }
    input, select{
        ${tw`
            w-full
            p-2
            border
            border-blue-800
            rounded-sm
            outline-none
            focus:border-2
            focus:border-blue-800

        `}
    }
`
const PoolFields = styled.div`
    ${tw`
        grid
        grid-cols-2
        gap-2
    `}
`

const PoolImages = styled.div`
    ${tw`
        flex
        flex-col
        px-5
    `}
`
const ImageHead = styled.div`
    ${tw`
        text-base
        font-semibold
    `}
`

const ShowCoverImage = styled.div`
    ${tw`
        flex
    `}
    img{
        ${tw`
            w-[250px]
            h-[150px]
        `}
    }
`

export function EditPool(){
    const {id} = useParams();
    return(
        <>
            <PageContainer>
                <Heading>
                    Pool ID : {id} 
                </Heading>

                <PoolInfoContainer>
                    <PoolFields>
                        <FormField>
                            <label>Pool Name</label>
                            <input type="text" name="poolName" placeholder="Pool Name" required />
                        </FormField>

                        <FormField>
                            <label>City</label>
                            <input type="text" name="poolCity" placeholder="E.g California City" required />
                        </FormField>

                        <FormField>
                            <label>State</label>
                            <input type="text" name="poolState" placeholder="E.g California" required />
                        </FormField>

                        <FormField>
                            <label>Country</label>
                            <input type="text" name="poolCountry" placeholder="E.g. USA" required />
                        </FormField>

                        <FormField>
                            <label>Rate</label>
                            <input type="decimal" name="poolRate" placeholder="E.g. 10.20" required />
                        </FormField>

                        <FormField>
                            <label>Capacity</label>
                            <input type="number" name="poolCapacity" placeholder="E.g. 120" required />
                        </FormField>

                        <FormField>
                            <label>Are Chilren Allowed</label>
                            <select name="chilrenAllowed">
                                <option selected disabled>Choose</option>
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </FormField>

                        <FormField>
                            <label>Cover Image</label>
                            <input type="file" />
                        </FormField>
                    </PoolFields>
                    <PoolImages>
                        <ImageHead>
                            Cover Image
                        </ImageHead>
                        <ShowCoverImage>
                            <img src="" alt="" />            
                        </ShowCoverImage>
                    </PoolImages>                   
                </PoolInfoContainer>
            </PageContainer>
        </>
    )
}