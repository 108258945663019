
import { createContext, useState } from "react";

export const AuthContext = createContext();

    const AppContext = ({children}) => {
    const [user, setUser] = useState(null)
    const [search, setSearch] = useState({})
    const [userDet, setUserDet] = useState({})
    

    const login = (usr) => {
        //console.log(usr)
        setUser(usr.login.jwt)
        localStorage.setItem('token',usr.login.jwt)
        setUserDet(usr.login)
        console.log(usr.login.user)
    }


    const logout = () =>{
        setUser(null)
        localStorage.removeItem('token')
    }

    const keepSearch = (parms) => {
        setSearch(parms)
    }

    return(
        <AuthContext.Provider value={{user, login, logout, keepSearch, userDet}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AppContext;