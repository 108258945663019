import React from "react";
import { Link, Outlet } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import { Header } from "../components/Header";
import AvatarImg from "./../assets/avatar/mouni.jpg"
import {AiFillCloseCircle} from "react-icons/ai"

const SubNavContainer = styled.div`
    ${tw`
        bg-red-400
        p-3
        lg:flex
        justify-between
        text-center
        items-center
        hidden

    `}
    a{
        ${tw`
            p-1
            px-5
            hover:text-gray-200
            hover:border-b-4
            hover:border-gray-200
            transition-all
            duration-100
        `}
    }
`

const SubNavMobileContainer = styled.div`
    ${tw`
        flex
        flex-col
        items-center
        w-full
        bg-blue-800
        fixed
        w-[200px]
        h-[100%]
        top-0
        left-0
        lg:hidden
        translate-x-[00%]
        px-2
        py-2
        gap-2
        font-Raleway
        z-10
    `}

    nav a{
        ${tw`
            flex
            w-full
            p-1
            text-white
            hover:underline
        `}
    }

    img{
        ${tw`
            rounded-[100%]
            h-[100px]
            w-[100px]
            border-4
            border-white
            object-cover
        `}
    }

`

const CloseNavContainer = styled.div`
    ${tw`
        flex
        w-full
        text-right
        items-end
        justify-end
        text-white
        cursor-pointer
    `}
`

export function MyAccount(){
    return(
        <>
            <Header />

            <SubNavContainer>
                <nav>
                    <Link to="/myaccount">Dashboard</Link>
                    <Link to="my-bookings">Bookings</Link>
                    <Link to="my-pools">Pools</Link>
                    <Link to="earnings">Earnings</Link>
                    {/*<Link to="users">Users</Link>*/}
                    <Link to="settings">Settings</Link>
                </nav>
            </SubNavContainer>

            <SubNavMobileContainer>
                <CloseNavContainer>
                    <AiFillCloseCircle size={20} />
                </CloseNavContainer>
                <img src={AvatarImg} alt=""/>
                <nav>
                    <Link to="/myaccount">Dashboard</Link>
                    <Link to="my-bookings">Bookings</Link>
                    <Link to="my-pools">Pools</Link>
                    <Link to="earnings">Earnings</Link>
                    {/*<Link to="users">Users</Link>*/}
                    <Link to="settings">Settings</Link>
                </nav>
            </SubNavMobileContainer>

            <Outlet />
        </>
    )
}