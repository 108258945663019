import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import LoginBG from "./../assets/bg2.jpg"

const ResetPasswordPage = styled.div`
    ${tw`
        flex
        w-full
        h-[100vh]
        object-fill
        relative
    `}
`

const BGOverlay = styled.div`
    ${tw`
        absolute
        w-[100vw]
        h-[100vh]
        bg-black
        opacity-50
        z-1
    `}
`

const ResetContainer = styled.div`
    ${tw`
        flex
        flex-col
        w-full
        items-center
        text-center
        justify-center
        z-10

    `}
`

const LogoContainer = styled.div`
    ${tw`
        flex
        text-center
        items-center
        justify-center
        font-Raleway
        text-4xl
        md:text-6xl
        text-white
    `}
`

const ResetFormContainer = styled.div`
    ${tw`
        flex
        flex-col
        bg-white
        w-[300px]
        md:w-[350px]
        h-auto
        p-6
        md:p-10
        mt-5
        rounded-md
    `}
`

const Header = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-center
        uppercase
        text-lg
        text-blue-800
    `}
`

const FormField = styled.div`
    ${tw`
        flex
        flex-col
        items-start
        justify-start
        text-left
    `}
    label{
        ${tw`
            w-full
            text-blue-800
        `}
    }
    input{
        ${tw`
            w-full
            p-2
            border
            border-blue-800
            rounded-sm
            outline-none
            focus:border-2
            focus:border-blue-800

        `}
    }
`

const ResetButtonContainer = styled.div`
    ${tw`
        flex
        w-full
        text-center
        items-center
        justify-center
        bg-red-400
        mt-3
        
    `}
    button{
        ${tw`
            p-2
            border-2
            border-blue-800
            w-full
            bg-blue-800
            text-white
            uppercase
            hover:border-2
            hover:border-blue-800
            hover:bg-white
            hover:text-blue-800
            transition-all
            duration-200
            ease-in-out
        `}
    }
`


export function ForgetPassword(){
    return(
        <ResetPasswordPage style={{backgroundImage : `url(${LoginBG})`}}>
            <BGOverlay />
            <ResetContainer>
                <LogoContainer>
                    <Link to="/">Swim</Link>
                </LogoContainer>
                <ResetFormContainer>
                    <Header>
                        Reset Password
                    </Header>
                    <form>
                        <FormField>
                            <label>Email</label>
                            <input type="email" name="email" placeholder="Email" />
                        </FormField>

                        <FormField>
                            <label>Enrollment Number</label>
                            <input type="text" name="enrollment_no" placeholder="Enrollment Number" />
                        </FormField>

                        <FormField>
                            <label>Registered Mobile Number</label>
                            <input type="text" name="registered_no" placeholder="Mobile Number" />
                        </FormField>

                        <ResetButtonContainer>
                            <button>Reset Password</button>
                        </ResetButtonContainer>
                    </form>
                </ResetFormContainer>
            </ResetContainer>
        </ResetPasswordPage>
    )
}