import React, { useState } from "react";
import { Header } from "../components/Header";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import LoginBG from "./../assets/bg2.jpg"
import { useMutation } from "@apollo/client";
import GQL_NEW_POOL from "../gql/AddPool";

const BGOverlay = styled.div`
    ${tw`
        absolute
        w-[100vw]
        h-[calc(100vh - 49px)]
        bg-black
        opacity-50
        z-1
    `}
`

const HostContainer = styled.div`
    ${tw`
        flex
        flex-col
        w-full
        h-[calc(100vh - 49px)]
        items-center
        text-center
        justify-center
        z-10

    `}
`

const LogoContainer = styled.div`
    ${tw`
        flex
        text-center
        items-center
        justify-center
        font-Raleway
        text-4xl
        md:text-6xl
        text-white
        z-10
    `}
`

const FormContainer = styled.div`
    ${tw`
        flex
        flex-col
        bg-white
        w-[300px]
        md:w-[350px]
        h-auto
        p-6
        md:p-10
        mt-5
        rounded-md
        z-10
    `}
`

const HostHeader = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-center
        uppercase
        text-lg
        text-blue-800
    `}
`

const FormField = styled.div`
    ${tw`
        flex
        flex-col
        items-start
        justify-start
        text-left
    `}
    label{
        ${tw`
            w-full
            text-blue-800
        `}
    }
    input, select{
        ${tw`
            w-full
            p-2
            border
            border-blue-800
            rounded-sm
            outline-none
            focus:border-2
            focus:border-blue-800

        `}
    }
`

const LoginButtonContainer = styled.div`
    ${tw`
        flex
        w-full
        text-center
        items-center
        justify-center
        mt-3
        
    `}
    button{
        ${tw`
            p-2
            border-2
            border-blue-800
            w-full
            bg-blue-800
            text-white
            uppercase
            hover:border-2
            hover:border-blue-800
            hover:bg-white
            hover:text-blue-800
            transition-all
            duration-200
            ease-in-out
        `}
    }
`


export function BecomeHost(){
    const [formData, setFormData] = useState({});
    const navigate = useNavigate()

    const handleChange = (e) => {
        e.preventDefault()
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        })
    }

    const handleAddPool = (e) =>{
        e.preventDefault();
        console.log(formData)
        addPool({
            variables:{
                data:{
                    name:formData.poolName,
                    City: formData.poolLocation,
                    rate: parseFloat(formData.poolrate),
                    capacity: parseInt(formData.poolcapacity),
                    pool_owner: 'test123@gmail.com'
                }
            }
        }).then(
            navigate("/myaccount/my-pools")
        )
    }

    const [addPool,{loading, error,data}] = useMutation(GQL_NEW_POOL,{
        context:{
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }
    })

    return(
        <>
            <Header />
            <HostContainer style={{backgroundImage : `url(${LoginBG})`}}>
                <BGOverlay />
                <LogoContainer>Swim</LogoContainer>
                <FormContainer>
                    <HostHeader>
                        Host Your Pool
                    </HostHeader>
                    <form onSubmit={handleAddPool}>
                        <FormField>
                            <label>Your Pool</label>
                            <input type="text" name="poolName" placeholder="Pool Name" required onChange={handleChange} />
                        </FormField>

                        <FormField>
                            <label>City</label>
                            <input type="text" name="poolLocation" placeholder="Pool Location" required onChange={handleChange} />
                        </FormField>

                        <FormField>
                            <label>Rate</label>
                            <input type="decimal" name="poolrate" placeholder="Eg. 2.00" min="1" required onChange={handleChange} />
                        </FormField>

                        <FormField>
                            <label>Capacity</label>
                            <input type="number" name="poolcapacity" placeholder="Eg. 100" min="1" required onChange={handleChange}/>
                        </FormField>

                        <FormField>
                            <label>Are Chilren Allowed</label>
                            <select name="chilrenAllowed" onChange={handleChange}>
                                <option selected disabled>Choose</option>
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </FormField>

                        <LoginButtonContainer>
                            <button>Setup Host Account</button>
                        </LoginButtonContainer>
                    </form>
                </FormContainer>
            </HostContainer>
        </>
    )
}