import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import LoginBG from "./../assets/bg2.jpg"
import REGISTER_USER from "./../gql/register"

const PageContainer = styled.div`
    ${tw`
        flex
        w-full
        h-[100vh]
        object-fill
        relative
    `}
`

const BGOverlay = styled.div`
    ${tw`
        absolute
        w-[100vw]
        h-[100vh]
        bg-black
        opacity-50
        z-1
    `}
`

const RegisterContainer = styled.div`
    ${tw`
        flex
        flex-col
        w-full
        items-center
        text-center
        justify-center
        z-10

    `}
`

const LogoContainer = styled.div`
    ${tw`
        flex
        text-center
        items-center
        justify-center
        font-Raleway
        text-4xl
        md:text-6xl
        text-white
    `}
`

const FormContainer = styled.div`
    ${tw`
        flex
        flex-col
        bg-white
        w-[300px]
        md:w-[350px]
        h-auto
        p-6
        md:p-10
        mt-5
        rounded-md
    `}
`

const Header = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-center
        uppercase
        text-lg
        text-blue-800
    `}
`

const FormField = styled.div`
    ${tw`
        flex
        flex-col
        items-start
        justify-start
        text-left
    `}
    label{
        ${tw`
            w-full
            text-blue-800
        `}
    }
    input{
        ${tw`
            w-full
            p-2
            border
            border-blue-800
            rounded-sm
            outline-none
            focus:border-2
            focus:border-blue-800

        `}
    }
`

const ButtonContainer = styled.div`
    ${tw`
        flex
        w-full
        text-center
        items-center
        justify-center
        bg-red-400
        mt-3
        
    `}
    button{
        ${tw`
            p-2
            border-2
            border-blue-800
            w-full
            bg-blue-800
            text-white
            uppercase
            hover:border-2
            hover:border-blue-800
            hover:bg-white
            hover:text-blue-800
            transition-all
            duration-200
            ease-in-out
        `}
    }
`

const ErrorContainer = styled.div`
    ${tw`
        flex
        p-1
        items-center
        text-center
        justify-center
        text-xs
        text-red-800
    `}
`
const LabelWithOption = styled.div`
    ${tw`
        flex
        items-center
        text-left
        justify-start
        gap-1
    `}
`

const Optional = styled.span`
    ${tw`
        text-[10px]
        text-gray-500
    `}
`


export function Register(){
    const navigate = useNavigate();
    const [formInput, setFormInput] = useState({
        email:'',
        password:'',
        confirmPassword:'',
        mobile:''
    })

    const [userReg,{loading, error, data}] = useMutation(REGISTER_USER)

    const [formError, setFormError] = useState({
        email: "",
        password: "",
        confirmPassword: "",
    });
    
    const handleChange = (name, value) => {

        setFormInput({
            ...formInput,
            [name]:value
        })
    }

    const validateFormInput = (e) => {
        e.preventDefault();
        let inputError = {
            email: "",
            password: "",
            confirmPassword: "",
          };

        if(formInput.password.length < 6){
            setFormError({
                ...inputError,
                confirmPassword:"Password must contain atleast 6 characters !!!"
            })
            return;
        }

        if(formInput.confirmPassword !== formInput.password){
            setFormError({
                ...inputError,
                confirmPassword:"Password Mismatch !!!"
            })
            return;
        }

        console.log(formInput);

        userReg({
            variables:{ 
                input:{
                    username:'Danish',
                    email: `${formInput.email}`,
                    password:`${formInput.confirmPassword}`
                }
            }
        })

        if(error){
            console.log(error)
        }


        setFormError(inputError);
    }

    useEffect(()=>{
        if(data){
            navigate('/login')
        }
    },[data])
    return(
        <PageContainer style={{backgroundImage : `url(${LoginBG})`}}>
            <BGOverlay />
            <RegisterContainer>
                <LogoContainer>
                    <Link to="/">Swim</Link>
                </LogoContainer>
                <FormContainer>
                    <Header>
                        Register
                    </Header>
                    <form onSubmit={validateFormInput}>
                        <FormField>
                            <label>Email</label>
                            <input type="email" name="email" placeholder="Eg. john.barron@domain.com" autoComplete="false" onChange={({target}) => handleChange(target.name, target.value)} required />
                        </FormField>

                        <FormField>
                            <label>Password</label>
                            <input type="password" name="password" placeholder="Password" onChange={({target}) => handleChange(target.name, target.value)} required />
                        </FormField>

                        <FormField>
                            <label>Confirm Password</label>
                            <input type="password" name="confirmPassword" placeholder="Re-enter password" onChange={({target}) => handleChange(target.name, target.value)} required/>
                        </FormField>
                        
                        <FormField>
                            <LabelWithOption>
                                <label>Mobile</label>
                                <Optional>(Optional)</Optional>
                            </LabelWithOption>
                            <input type="text" name="mobile" placeholder="10 digit mobile number" onChange={({target}) => handleChange(target.name, target.value)} />
                        </FormField>

                        <ButtonContainer>
                            <button type="submit">Register</button>
                        </ButtonContainer>

                        {
                            formError &&
                            <ErrorContainer>
                                <p className="error-message">{formError.confirmPassword}</p>
                            </ErrorContainer>
                        }

{
                            formError &&
                            <ErrorContainer>
                                <p className="error-message">{formError.password}</p>
                            </ErrorContainer>
                        }
                        
                    </form>
                </FormContainer>
            </RegisterContainer>
        </PageContainer>
    )
}