import { gql } from "@apollo/client";

const USER_LOGIN = gql`
    mutation Login($input: UsersPermissionsLoginInput!) {
        login(input: $input) {
          jwt,
          user {
            email
            id
            username
          }
        }
    }
`

export default USER_LOGIN;