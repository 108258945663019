import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import './App.css';
import { BecomeHost } from './containers/BecomeHost';
import { Earnings } from './containers/Earnings';
import { ForgetPassword } from './containers/ForgetPassword';
import { Home } from './containers/Home';
import { Login } from './containers/Login';
import { MyAccount } from './containers/MyAccount';
import { MyBookings } from './containers/MyBookings';
import { Pools } from './containers/Pools';
import { Register } from './containers/Register';
import { Settings } from './containers/Settings';
import { UpcomingBookings } from './containers/UpcomingBookings';
import AppContext from './utils/context';
import { ProtectedRoutes } from './containers/ProtectedRoutes';
import { PoolDetail } from './containers/PoolDetail';
import { BookNow } from './containers/book-now';
import { MyPools } from './containers/MyPools';
import { EditPool } from './containers/EditPool';


//Apollo Client
const client = new ApolloClient({
  uri: 'dpg-cg6sos5269v5l67c8e20-a.oregon-postgres.render.com/graphql',
  //uri: 'http://localhost:1337/graphql',
  cache: new InMemoryCache()
})


function App() {
  return (
    <ApolloProvider client={client}>
    <AppContext>
        <BrowserRouter>
          <Routes>
              <Route path='/' element={<Home/>} />
              <Route path='/login' element={<Login />} />
              <Route path='/register' element={<Register />} />
              <Route path='/pools' element={<Pools />} />
              <Route path='/pool/:id' element={<PoolDetail />} />
              <Route path='/book-now/:id' element={<ProtectedRoutes><BookNow /></ProtectedRoutes>} />
              <Route path='/become-host' element={<BecomeHost />} />
              <Route path='/myaccount' element={<ProtectedRoutes><MyAccount /></ProtectedRoutes>}>
                <Route index  element={<ProtectedRoutes><UpcomingBookings /></ProtectedRoutes>} />
                <Route path='my-pools' element={<ProtectedRoutes><MyPools /></ProtectedRoutes>} />
                <Route path='edit-pool/:id' element={<ProtectedRoutes><EditPool /></ProtectedRoutes>} />
                <Route path='earnings' element={<ProtectedRoutes><Earnings /></ProtectedRoutes>} />
                <Route path='my-bookings' element={<ProtectedRoutes><MyBookings /></ProtectedRoutes>} />
                <Route path='settings' element={<ProtectedRoutes><Settings /></ProtectedRoutes>} />
              </Route>
              <Route path='/forget-password' element={<ForgetPassword />} />
            </Routes>
        </BrowserRouter>
    </AppContext>
    </ApolloProvider>
  );
}

export default App;
