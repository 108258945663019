import React, { useMemo, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {CiEdit} from "react-icons/ci"
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import ALL_BOOKINGS from "../gql/Bookings";
import { useTable, usePagination } from "react-table";

const PageContainer = styled.div`
    ${tw`
        w-full
        p-10
    `}
`

const TableContainer = styled.div`
     ${tw`
        w-full
        box-border
        p-0
        m-0        
     `}
    table{
        ${tw`
            table-auto
            w-full
        `}
    }
    table td{
        ${tw`
            text-sm 
            px-3
            py-4
            text-left
        `}
    }

    table th{
        ${tw`
            text-sm 
            px-3
            py-4
            text-left
            border-2
            border-solid
            border-l-0
            border-r-0
            font-bold
        `}
    }

    table tbody tr{
        ${tw`
            hover:bg-gray-100
            border-b
            border-gray-200
        `}
    }

    table tbody tr td{
        ${tw`
            first:font-semibold
            first:text-blue-800
            first:underline
            first:hover:cursor-pointer
            first:flex
            first:items-center
            first:text-left
            first:justify-start
            last:font-bold
            last:relative
        `}
    }
`

const ActionContainer = styled.div`
    ${tw`
        w-full
        mb-2
        p-2
        flex
        gap-2
        items-center
        justify-end
    `}
    button{
        ${tw`
            bg-red-400
            px-4
            py-1
            rounded-full
        `}
    }
    select{
        ${tw`
            border
            border-gray-300
        `}
    }
`

const LinkPopUpCOntainer = styled.div`
    ${tw`
        absolute
        box-border
        my-1
        flex
        flex-col
    `}
`

const PaginationContainer = styled.div`
    ${tw`
        mt-5
        p-2
        flex
        items-center
        text-center
        justify-end
        gap-3

    `}
    button{
        ${tw`
            bg-red-400
            p-2
            rounded-lg
            hover:text-white
        `}
    }
`

const PopupMenu = styled.div`
    ${tw`
        absolute
        bg-red-500
        z-10
        hidden
    `}
`

export function MyBookings(){
    const [subMenuDisplay, setSubMenuDisplay] = useState(true);
    const {loading, error, data} = useQuery(ALL_BOOKINGS,{
        context:{
            headers:{
                Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc3NDUwNDUyLCJleHAiOjE2ODAwNDI0NTJ9.edFo8LyE-qFp-q55XvkCqezdvXEmj2xvbg8vvNrKG9Y'
            }
        }
    })

    if(data){
        console.log(data)
    }


    return(
        <PageContainer>
            <ActionContainer>
                <span>Actions</span>
                <select>
                    <option selected disabled>Choose</option>
                    <option>Delete</option>
                    <option>Cancell</option>
                </select>
                <button>GO</button>
            </ActionContainer>
            {
                loading && <div>Loading...</div>
            }

            {
                error && <div>{error}</div>
            }

            {
                data &&
                <>
                    <TableContainer>
                        <table>
                            <thead>
                                <tr>
                                    <th><input type="checkbox" /></th>
                                    <th>ID</th>
                                    <th>Pool Name</th>
                                    <th>Booking Person</th>
                                    <th>Booking Email</th>
                                    <th>Booking Start</th>
                                    <th>Start Time</th>
                                    <th>Booking End</th>
                                    <th>End Time</th>
                                    <th>Amount</th>
                                    <th>Payment Status</th>
                                    <th>Booking Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.bookings.data.map((row) =>{
                                        return(
                                            <tr key={row.id}>
                                                <td><input type="checkbox" /></td>
                                                <td>{row.id}</td>
                                                <td>{row.attributes.pool.data.attributes.name}</td>
                                                <td>{row.attributes.booking_person}</td>
                                                <td>{row.attributes.booking_email}</td>
                                                <td>{row.attributes.booking_start}</td>
                                                <td>{row.attributes.booking_start_time}</td>
                                                <td>{row.attributes.booking_end}</td>
                                                <td>{row.attributes.booking_end_time}</td>
                                                <td>{row.attributes.booking_amount}</td>
                                                <td>{row.attributes.payment_status}</td>
                                                <td>{row.attributes.booking_status}</td>
                                                <td>
                                                    <Link to={`edit-pool/${row.id}`}><CiEdit color="blue" size={20} /></Link>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>           
                    </TableContainer>
                </>
            }     
        </PageContainer>
    )
}