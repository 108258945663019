import gql from "graphql-tag";

const REGISTER_USER = gql`
    mutation Mutation($input: UsersPermissionsRegisterInput!) {
        register(input: $input) {
          jwt
          user {
                username
                email
                confirmed
            }
        }
    }
`
export default REGISTER_USER;