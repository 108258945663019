import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { FaStarHalf, FaStar, FaRegStar } from "react-icons/fa";
import P1 from "./../assets/p1.png"
import P2 from "./../assets/p2.jpg"
import P3 from "./../assets/p3.jpg"
import P4 from "./../assets/p4.jpg"

const FamousSection = styled.section`
    ${tw`
        flex-col
        w-full
        text-center
        justify-center
        items-center
        bg-blue-800
        py-2
    `}
`

const HeaderCont = styled.div`
    ${tw`
        flex
        items-start
        lg:text-2xl
        text-lg
        font-Raleway
        text-white
        lg:px-10
        px-5
        py-3
    `}
`

const PoolGridContainer = styled.div`
    ${tw`
        grid
        grid-cols-1
        md:grid-cols-2
        lg:grid-cols-4
        gap-4
        w-[90%]
        lg:w-[90%]
        w-[96%]
        /*bg-blue-500*/
        mx-auto
        md:py-2
    `}
`

const PoolCardContainer = styled.div`
    ${tw`
        col-span-1
        flex-col
        gap-2
        relative
    `}
`

const PoolCard = styled.div`
    ${tw`
        flex-col
        w-full
        relative
    `}
    img{
        ${tw`
            h-full
            w-full
            object-cover
            rounded-md
        `}
        box-shadow:0px 0px 5px black
    }

`

const PoolDetails = styled.div`
    ${tw`
        flex
        flex-col
        text-white
        font-Raleway
        p-2
    `}
`

const PoolNameContainer = styled.div`
    ${tw`
        flex
        w-full
        sm:text-xs
        md:text-xs
        lg:text-sm
        text-center
        justify-between
        items-center
    `}
`

const PoolRatingContainer = styled.div`
    ${tw`
        flex
        gap-1
        text-yellow-300
    `}
`

export function Famous(){
    return(
        <FamousSection>
            <HeaderCont>
                Popular these days...
            </HeaderCont>
            <PoolGridContainer>
                <PoolCardContainer>
                    <PoolCard>
                        <img src={P1} alt="" />
                        <PoolDetails>
                            <PoolNameContainer>
                                <span>Moon Valley</span>
                                <span>Rate: $10/hr</span>
                            </PoolNameContainer>
                            <PoolRatingContainer>
                                <FaStar size={18} />
                                <FaStar size={18} />
                                <FaStar size={18} />
                                <FaStar size={18} />
                                <FaStarHalf size={18} />
                            </PoolRatingContainer>
                        </PoolDetails>
                    </PoolCard>
                    
                </PoolCardContainer>

                <PoolCardContainer>
                    <PoolCard>
                        <img src={P2} alt="" />
                        <PoolDetails>
                            <PoolNameContainer>
                                <span>Moon Valley</span>
                                <span>Rate: $5/hr</span>
                            </PoolNameContainer>
                            <PoolRatingContainer>
                                <FaStar size={18} />
                                <FaStar size={18} />
                                <FaStar size={18} />
                                <FaStar size={18} />
                                <FaStarHalf size={18} />
                            </PoolRatingContainer>
                        </PoolDetails>
                    </PoolCard>
                    
                </PoolCardContainer>

                <PoolCardContainer>
                    <PoolCard>
                        <img src={P3} alt="" />
                        <PoolDetails>
                            <PoolNameContainer>
                                <span>Moon Valley</span>
                                <span>Rate: $5/hr</span>
                            </PoolNameContainer>
                            <PoolRatingContainer>
                                <FaStar size={18} />
                                <FaStar size={18} />
                                <FaStar size={18} />
                                <FaStar size={18} />
                                <FaStarHalf size={18} />
                            </PoolRatingContainer>
                        </PoolDetails>
                    </PoolCard>
                    
                </PoolCardContainer>

                <PoolCardContainer>
                    <PoolCard>
                        <img src={P4} alt="" />
                        <PoolDetails>
                            <PoolNameContainer>
                                <span>Moon Valley</span>
                                <span>Rate:  $3/hr</span>
                            </PoolNameContainer>
                            <PoolRatingContainer>
                                <FaStar size={18} />
                                <FaStar size={18} />
                                <FaStar size={18} />
                                <FaStar size={18} />
                                <FaStarHalf size={18} />
                            </PoolRatingContainer>
                        </PoolDetails>
                    </PoolCard>
                    
                </PoolCardContainer>

            </PoolGridContainer>
            
        </FamousSection>
    )
}