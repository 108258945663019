import { gql } from "@apollo/client";

const ALL_BOOKINGS = gql`
query Bookings {
    bookings {
        data {
            id
            attributes{
                pool{
                    data{
                        id
                        attributes{
                            name
                        }
                    }
                }
                booking_person
                booking_email
                booking_start
                booking_start_time
                booking_end
                booking_end_time
                booking_amount
                payment_status
                booking_status
            }
        }
    }
}
`

export default ALL_BOOKINGS;